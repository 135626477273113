import Parseable from "@/models/Parseable";
import { Plug } from "@/enum/Plug.enum";

export default class ChargingPrice extends Parseable {
  name!: string;
  price!: number;
  current!: string;
  plug?: Plug;
  userGroup?: string;
  blockingFeeBegin?: number;
  blockingFeeIncreaseAmount?: number;
  blockingFeeMaxPrice?: number;
  id?: string;

  static parseFromObject(object: any): ChargingPrice {
    const chargingPrice = new ChargingPrice();
    Object.assign(chargingPrice, object);

    return chargingPrice;
  }

  parseToObject(): any {
    return { ...this };
  }
}
