import { CLIENT } from "@/api/misc/Client";
import { Manufacturer } from "@/enum/Manufacturer.enum";
import { ChargingStationAccessibility } from "@/enum/ChargingStationAccessibility.enum";
import { CreateChargingPointWithChargingStationDto } from "@/api/dtos/create-charging-point-with-charging-station-dto";
import ChargingPoint from "@/models/ChargingPoint";

export default {
  /**
   * Create a charging station
   * @param payload
   */
  async create(payload: {
    name: string;
    evseId: string;
    street: string;
    houseNr: string;
    country: string;
    postCode: string;
    city: string;
    manufacturer: Manufacturer;
    stationLat: number;
    stationLong: number;
    entryLat?: number;
    entryLong?: number;
    exitLat?: number;
    exitLong?: number;
    customManufacturer?: string;
    note?: string;
    simCard?: string;
    tenantId: string;
    chargingPoints: CreateChargingPointWithChargingStationDto[];
  }) {
    return CLIENT.post("charging-station", payload);
  },

  /**
   * Get list of all charging stations
   */
  async getAll(skip?: string, limit?: string): Promise<any> {
    return CLIENT.get(`charging-station?limit=${limit}&skip=${skip}`);
  },

  /**
   * Get one charging station by id
   */
  async getChargingStation(id: string) {
    return CLIENT.get(`charging-station/${id}`);
  },

  /**
   * Update one charging station by id
   */
  async updateChargingStation(
    id: string,
    name: string,
    evseId: string,
    note: string,
    street: string,
    houseNr: string,
    country: string,
    postCode: string,
    city: string,
    manufacturer: Manufacturer,
    chargingPoints: ChargingPoint[],
    alwaysOpen?: boolean,
    startTime?: string | null | undefined,
    closeTime?: string | null | undefined,
    accessibility?: ChargingStationAccessibility,
    parkingLevel?: string | null | undefined,
    simCard?: string | null | undefined,
    parkingNumber?: string | null | undefined,
    isOnlyForElectricVehicles?: boolean | null | undefined,
    isOnlyForMotorCycles?: boolean | null | undefined,
    stationLat?: number | null,
    stationLong?: number | null,
    entryLat?: number | null,
    entryLong?: number | null,
    exitLat?: number | null,
    exitLong?: number | null
  ) {
    return CLIENT.patch(`charging-station/${id}`, {
      name,
      evseId,
      note,
      street,
      houseNr,
      country,
      postCode,
      city,
      accessibility,
      manufacturer,
      chargingPoints,
      alwaysOpen,
      startTime,
      closeTime,
      parkingLevel,
      simCard,
      parkingNumber,
      isOnlyForElectricVehicles,
      isOnlyForMotorCycles,
      stationLat,
      stationLong,
      entryLat,
      entryLong,
      exitLat,
      exitLong,
    });
  },
  /**
   * Delete one charging station by id
   */
  async deleteChargingStation(id: string) {
    return CLIENT.delete(`charging-station/${id}`);
  },
  async downloadAllChargingStations() {
    return await CLIENT.get("charging-station/download/csv");
  },
  async downloadChargingStationById(id: string) {
    return CLIENT.get(`charging-station/${id}/download/csv`);
  },
};
