import { CLIENT } from "@/api/misc/Client";
import { Plug } from "@/enum/Plug.enum";

export default {
  /**
   * Get all charging price items from db
   */
  async getAll() {
    return CLIENT.get("charging-price");
  },

  /**
   * Create a charging price
   * @param payload
   */
  async create(payload: {
    tenantId: string;
    name: string;
    price: number;
    current: string;
    plug?: Plug;
    userGroup?: string;
    blockingFeeBegin?: number;
    blockingFeeIncreaseAmount?: number;
    blockingFeeMaxPrice?: number;
  }) {
    return CLIENT.post("charging-price", payload);
  },

  /**
   * Update one charging price by id
   */
  async update(
    id: string,
    tenantId: string,
    name: string,
    price: number,
    current: string,
    plug?: Plug,
    userGroup?: string,
    blockingFeeBegin?: number,
    blockingFeeIncreaseAmount?: number,
    blockingFeeMaxPrice?: number
  ) {
    return CLIENT.patch(`charging-price/${id}`, {
      tenantId,
      name,
      price,
      current,
      plug,
      userGroup,
      blockingFeeBegin,
      blockingFeeIncreaseAmount,
      blockingFeeMaxPrice,
    });
  },
};
