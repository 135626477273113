import { CLIENT } from "@/api/misc/Client";

export default {
  /**
   * Logs user into the charging-point-overview-dashboard
   */
  async login(payload: { username: string; password: string }) {
    return CLIENT.post("auth/login", payload);
  },

  /**
   * Refresh expired jwt token
   * @param payload
   */
  async refreshToken(payload: { refreshToken: string }) {
    return CLIENT.post("auth/refreshToken", payload);
  },

  /**
   * Create a new User
   * @param payload
   */
  async createUser(payload: {
    tenantId: string | null;
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phone: string;
    roleId: string;
  }) {
    return CLIENT.post("user", payload);
  },

  async updateUser(
    id: string,
    tenantId: string,
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    roleId: string,
    phone: string
  ) {
    return CLIENT.patch(`user/${id}`, {
      tenantId,
      email,
      password,
      firstName,
      lastName,
      roleId,
      phone,
    });
  },

  /**
   * Get list of all users
   */
  async getAll(): Promise<any> {
    return CLIENT.get(`user`);
  },

  /**
   * Get Role by id
   */
  async getRoleById(id: string): Promise<any> {
    return CLIENT.get(`role/${id}`);
  },

  /**
   * Get User by id
   */
  async getUserById(id: string): Promise<any> {
    return CLIENT.get(`user/${id}`);
  },

  /**
   * Get all Roles
   */
  async getAllRoles(): Promise<any> {
    return CLIENT.get("role");
  },

  /**
   * Set first password
   */
  async setPassword(payload: { hash: string; password: string }): Promise<any> {
    return CLIENT.post(`auth/setPassword/${payload.hash}`, {
      password: payload.password,
    });
  },
};
