import Parseable from "@/models/Parseable";
/**
 * Represents the tenant
 */
export default class Tenant extends Parseable {
  public id!: string;
  public name!: string;

  public static parseFromObject(object: any): Tenant {
    const tenant = new Tenant();
    Object.assign(tenant, object);
    return tenant;
  }

  parseToObject(): Partial<Tenant> {
    return { ...this };
  }
}
