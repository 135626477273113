import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import LightTheme from './themes/LightTheme';
import DarkTheme from './themes/DarkTheme';

// Translation provided by Vuetify (typescript)
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default value
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: LightTheme,
            dark: DarkTheme,
        },
    },
});
