import ChargeProcess from "@/models/ChargeProcess";
import ChargingStation from "@/models/ChargingStation";
import { ChargingPointStatus } from "@/enum/ChargingPointStatus.enum";
import Parseable from "@/models/Parseable";
import { Manufacturer } from "@/enum/Manufacturer.enum";
import { Plug } from "@/enum/Plug.enum";

export default class ChargingPoint extends Parseable {
  id!: string;
  evseId!: string;
  requiresAuthentication!: boolean;
  plug!: Plug | null;
  chargingStation?: ChargingStation;
  chargeProcesses?: ChargeProcess[];
  isAvailableForCharging!: boolean;
  status!: ChargingPointStatus;
  manufacturer!: Manufacturer;

  static parseFromObject(object: any): ChargingPoint {
    const chargingPoint = new ChargingPoint();
    Object.assign(chargingPoint, object);

    chargingPoint.chargeProcesses = <ChargeProcess[]>(
      ChargeProcess.parseFromArray(chargingPoint.chargeProcesses!)
    );

    return chargingPoint;
  }

  parseToObject(): any {
    return { ...this };
  }
}
