import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";
import store from "@/store";
import ApiError from "@/api/misc/ApiError";
import { AuthStoreGetters, AuthStoreMutations } from "@/store/auth.store";
import AuthRepository from "@/api/repositories/Auth.repository";

const baseURL: string = process.env.VUE_APP_API_URL!;
const axiosClient: AxiosInstance = axios.create({
  baseURL,
  timeout: 10000,
});

/**
 * Sets the request interceptors
 * Sets authorization header for each request
 */
axiosClient.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (store.getters[`auth/${AuthStoreGetters.TOKEN}`]) {
      const token = store.getters[`auth/${AuthStoreGetters.TOKEN}`];
      config.headers = { ...config.headers, authorization: token };

      if (token && config.headers) {
        config.headers.authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Sets the response interceptors. Normalizes the response and handles certain error cases
 */
axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: any) => {
    console.log(JSON.stringify(error));

    if (error.response) {
      switch (error.response.status) {
        case 401: {
          const refreshToken =
            store.getters[`auth/${AuthStoreGetters.REFRESH_TOKEN}`];

          const res = await AuthRepository.refreshToken({
            refreshToken: refreshToken,
          });

          const newRefreshToken = res.data.refresh_token;
          const newAccessToken = res.data.access_token;

          await store.commit(
            `auth/${AuthStoreMutations.SET_REFRESH_TOKEN}`,
            newRefreshToken
          );

          await store.commit(
            `auth/${AuthStoreMutations.SET_TOKEN}`,
            newAccessToken
          );

          return await axiosClient.request(error.config);
        }
      }

      if (error.response >= 500) {
        Vue.notifyErrorSimplified("GENERAL.NOTIFICATIONS.GENERAL_ERROR");
      } else {
        throw new ApiError(
          error.response.status,
          error.message,
          error.response.data.data
        );
      }
    } else {
      Vue.notifyErrorSimplified("GENERAL.NOTIFICATIONS.NETWORK_ERROR");
    }
  }
);

export const CLIENT = axiosClient;
