import ChargingPoint from "@/models/ChargingPoint";
import { TransactionStatus } from "@/enum/TransactionStatus.enum";
import Parseable from "@/models/Parseable";

export default class ChargeProcess extends Parseable {
  authCardToken?: string;
  contractId?: string;
  startTime?: string;
  endTime?: string;
  duration?: string;
  chargedAmount?: string;
  transactionNumber?: string;
  chargingStationName?: string;
  evseId?: string;
  chargingPoint?: ChargingPoint;
  transactionStatus?: TransactionStatus;
  netto?: number;
  brutto?: number;
  isPaid?: boolean;

  static parseFromObject(object: any): ChargeProcess {
    const chargeProcess = new ChargeProcess();
    Object.assign(chargeProcess, object);
    return chargeProcess;
  }

  parseToObject(): any {
    return { ...this };
  }
}
