import ChargingStation from "@/models/ChargingStation";
import {MessageType} from "@/enum/MessageType.enum";
import Parseable from "@/models/Parseable";

export default class StationMessage extends Parseable{
    type?: MessageType;
    chargingStation?: ChargingStation;
    gatewayId?: string;
    protocol?: string;
    status?: string;

    parseToObject(): any {
        return { ...this };
    }

}
