import { CLIENT } from "@/api/misc/Client";

export default {
  /**
   * Get list of all tenants
   */
  async getAll() {
    return CLIENT.get("tenant");
  },

  /**
   * Get list of users from one tenant
   */
  async getByTenantId(id: string) {
    return CLIENT.get(`tenant/${id}`);
  },
};
