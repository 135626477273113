import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import ChargingStationRepository from "@/api/repositories/ChargingStationRepository";
import ChargingStation from "@/models/ChargingStation";
import { ChargingStationState } from "@/interfaces/StoreStateInterfaces";
import ChargingPoint from "@/models/ChargingPoint";
import ChargingPointRepository from "@/api/repositories/ChargingPointRepository";
import { Manufacturer } from "@/enum/Manufacturer.enum";
import { ChargingPointStatus } from "@/enum/ChargingPointStatus.enum";
import { Plug } from "@/enum/Plug.enum";
import ChargeProcess from "@/models/ChargeProcess";
import ChargeProcessRepository from "@/api/repositories/ChargeProcessRepository";
import ControlChargingStationRepository from "@/api/repositories/ControlChargingStationRepository";
import { ChargingStationAccessibility } from "@/enum/ChargingStationAccessibility.enum";
import { CreateChargingPointWithChargingStationDto } from "@/api/dtos/create-charging-point-with-charging-station-dto";
import ChargingPriceRepository from "@/api/repositories/ChargingPriceRepository";
import ChargingPrice from "@/models/ChargingPrice";

export const CHARGING_STATION_STORE_NAME = "chargingStation";

function initChargingStationState(): ChargingStationState {
  return {
    chargingStations: [],
    chargingStation: undefined,
    chargeProcesses: [],
    total: undefined,
  };
}

const store: ChargingStationState = initChargingStationState();

export const enum ChargingStationActions {
  DELETE_CHARGING_STATION = "DELETE_CHARGING_STATION",
  DELETE_CHARGING_POINT = "DELETE_CHARGING_POINT",
  UPDATE_CHARGING_STATION = "UPDATE_CHARGING_STATION",
  UPDATE_CHARGING_POINT = "UPDATE_CHARGING_POINT",
  GET_CHARGING_STATION = "GET_CHARGING_STATION",
  DOWNLOAD_ALL_CHARGING_STATIONS_IN_CSV_FORMAT = "DOWNLOAD_ALL_CHARGING_STATIONS_IN_CSV_FORMAT",
  DOWNLOAD_CHARGING_STATION = "DOWNLOAD_CHARGING_STATION",
  DOWNLOAD_CHARGE_PROCESS = "DOWNLOAD_CHARGE_PROCESS",
  GET_ALL = "GET_ALL",
  GET_ALL_CHARGING_PRICES = "GET_ALL_CHARGING_PRICES",
  GET_CONFIGURATION = "GET_CONFIGURATION",
  GET_ALL_CHARGE_PROCESSES = "GET_ALL_CHARGE_PROCESSES",
  CREATE_CHARGING_STATION = "CREATE_CHARGING_STATION",
  CREATE_CHARGING_PRICE = "CREATE_CHARGING_PRICE",
  UPDATE_CHARGING_PRICE = "UPDATE_CHARGING_PRICE",
  CREATE_CHARGING_POINT = "CREATE_CHARGING_POINT",
  REMOTE_START = "REMOTE_START",
  REMOTE_STOP = "REMOTE_STOP",
  RESET = "RESET",
  UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION",
}

export const enum ChargingStationMutations {
  SET_CHARGING_STATION = "SET_CHARGING_STATION",
  CLEAR_STORE = "CLEAR_STORE",
  SET_ALL = "SET_ALL",
  SET_ALL_CHARGE_PROCESSES = "SET_ALL_CHARGE_PROCESSES",
  SET_TOTAL_CHARGE_LIST = "SET_TOTAL_CHARGE_LIST",
}

export const enum ChargingStationGetters {
  CURRENT_CHARGING_STATION = "CURRENT_CHARGING_STATION",
  CHARGING_STATIONS = "CHARGING_STATIONS",
  CURRENT_TOTAL_CHARGE_LIST = "CURRENT_TOTAL_CHARGE_LIST",
}

const actions: ActionTree<ChargingStationState, any> = {
  [ChargingStationActions.DOWNLOAD_ALL_CHARGING_STATIONS_IN_CSV_FORMAT]:
    async ({ commit }): Promise<any> => {
      return await ChargingStationRepository.downloadAllChargingStations();
    },
  [ChargingStationActions.DOWNLOAD_CHARGE_PROCESS]: async (
    { commit },
    payload: { value?: string }
  ): Promise<any> => {
    return await ChargeProcessRepository.downloadChargeProcess(payload.value);
  },
  [ChargingStationActions.DOWNLOAD_CHARGING_STATION]: async (
    { commit },
    payload: { id: string }
  ): Promise<any> => {
    return await ChargingStationRepository.downloadChargingStationById(
      payload.id
    );
  },
  [ChargingStationActions.CREATE_CHARGING_POINT]: async (
    { commit },
    payload: {
      evseId: string;
      status: ChargingPointStatus;
      plug: Plug;
      chargingStationId: string;
      isAvailableForCharging: boolean;
      requiresAuthentication: boolean;
      note?: string;
      tenantId: string;
    }
  ): Promise<ChargingPoint> => {
    const res = await ChargingPointRepository.create(payload);
    return ChargingPoint.parseFromObject(res.data);
  },
  [ChargingStationActions.CREATE_CHARGING_STATION]: async (
    { commit },
    payload: {
      name: string;
      evseId: string;
      simCard: string;
      street: string;
      houseNr: string;
      country: string;
      postCode: string;
      city: string;
      manufacturer: Manufacturer;
      stationLat: number;
      stationLong: number;
      entryLat?: number;
      entryLong?: number;
      exitLat?: number;
      exitLong?: number;
      customManufacturer?: string;
      note?: string;
      tenantId: string;
      chargingPoints: CreateChargingPointWithChargingStationDto[];
    }
  ): Promise<ChargingStation> => {
    const res = await ChargingStationRepository.create(payload);
    return ChargingStation.parseFromObject(res.data);
  },
  [ChargingStationActions.GET_ALL]: async (
    { commit },
    payload: { skip?: string; limit?: string }
  ): Promise<ChargingStation[]> => {
    const res = await ChargingStationRepository.getAll(
      payload.skip,
      payload.limit
    );
    const chargingStations: ChargingStation[] = <ChargingStation[]>(
      ChargingStation.parseFromArray(res.data.records)
    );
    commit(ChargingStationMutations.SET_ALL, chargingStations);
    commit(ChargingStationMutations.SET_TOTAL_CHARGE_LIST, res.data.total);
    return chargingStations;
  },
  [ChargingStationActions.GET_CONFIGURATION]: async (
    { commit },
    payload: { chargePointId: string; key?: string[] }
  ): Promise<any> => {
    const res = await ControlChargingStationRepository.getConfig(payload);

    return JSON.stringify(res.data, null, "\t");
  },
  [ChargingStationActions.GET_ALL_CHARGE_PROCESSES]: async ({
    commit,
  }): Promise<ChargeProcess[]> => {
    const res = await ChargeProcessRepository.getAll();

    const chargeProcesses: ChargeProcess[] = <ChargeProcess[]>(
      ChargeProcess.parseFromArray(res.data.records)
    );
    commit(ChargingStationMutations.SET_ALL_CHARGE_PROCESSES, chargeProcesses);
    return chargeProcesses;
  },

  [ChargingStationActions.GET_CHARGING_STATION]: async (
    { commit },
    payload: { id: string }
  ): Promise<ChargingStation> => {
    const res = await ChargingStationRepository.getChargingStation(payload.id);

    return ChargingStation.parseFromObject(res.data);
  },

  [ChargingStationActions.UPDATE_CHARGING_STATION]: async (
    { commit },
    payload: {
      id: string;
      name: string;
      evseId: string;
      note: string;
      street: string;
      houseNr: string;
      country: string;
      postCode: string;
      city: string;
      manufacturer: Manufacturer;
      chargingPoints: ChargingPoint[];
      alwaysOpen?: boolean;
      startTime?: string;
      closeTime?: string;
      accessibility: ChargingStationAccessibility;
      parkingLevel?: string;
      simCard?: string;
      parkingNumber?: string;
      isOnlyForElectricVehicles?: boolean;
      isOnlyForMotorCycles?: boolean;
      stationLat?: number | null;
      stationLong?: number | null;
      entryLat?: number | null;
      entryLong?: number | null;
      exitLat?: number | null;
      exitLong?: number | null;
    }
  ): Promise<ChargingStation> => {
    const res = await ChargingStationRepository.updateChargingStation(
      payload.id,
      payload.name,
      payload.evseId,
      payload.note,
      payload.street,
      payload.houseNr,
      payload.country,
      payload.postCode,
      payload.city,
      payload.manufacturer,
      payload.chargingPoints,
      payload.alwaysOpen,
      payload.startTime,
      payload.closeTime,
      payload.accessibility,
      payload.parkingLevel,
      payload.simCard,
      payload.parkingNumber,
      payload.isOnlyForElectricVehicles,
      payload.isOnlyForMotorCycles,
      payload.stationLat,
      payload.stationLong,
      payload.entryLat,
      payload.entryLong,
      payload.exitLat,
      payload.exitLong
    );
    return ChargingStation.parseFromObject(res.data);
  },
  [ChargingStationActions.UPDATE_CHARGING_POINT]: async (
    { commit },
    payload: {
      id: string;
      plug: Plug;
      status: ChargingPointStatus;
      chargingStationId: string;
      evseId: string;
      isAvailableForCharging: boolean;
      requiresAuthentication: boolean;
    }
  ): Promise<ChargingPoint> => {
    const res = await ChargingPointRepository.updateChargingPoint(
      payload.id,
      payload.plug,
      payload.status,
      payload.chargingStationId,
      payload.evseId,
      payload.isAvailableForCharging,
      payload.requiresAuthentication
    );
    return ChargingPoint.parseFromObject(res.data);
  },
  [ChargingStationActions.DELETE_CHARGING_STATION]: async (
    { commit },
    payload: { id: string }
  ): Promise<void> => {
    await ChargingStationRepository.deleteChargingStation(payload.id);
  },
  [ChargingStationActions.DELETE_CHARGING_POINT]: async (
    { commit },
    payload: { id: string }
  ): Promise<void> => {
    await ChargingPointRepository.deleteChargePoint(payload.id);
  },
  [ChargingStationActions.REMOTE_START]: async (
    { commit },
    payload: {
      chargePointId: string;
      idTag: string;
      connectorId: number;
    }
  ): Promise<any> => {
    const res = await ControlChargingStationRepository.remoteStart(payload);
    return res.data;
  },
  [ChargingStationActions.REMOTE_STOP]: async (
    { commit },
    payload: {
      chargePointId: string;
      transactionId: number;
      connectorId: number;
      idTag: string;
    }
  ): Promise<void> => {
    await ControlChargingStationRepository.remoteStop(payload);
  },
  [ChargingStationActions.RESET]: async (
    { commit },
    payload: { chargePointId: string; type: string }
  ): Promise<void> => {
    await ControlChargingStationRepository.reset(payload);
  },
  [ChargingStationActions.UPDATE_CONFIGURATION]: async (
    { commit },
    payload: {
      chargePointId: string;
      key: string;
      value: string;
    }
  ): Promise<ChargingPoint> => {
    const res = await ControlChargingStationRepository.updateConfiguration(
      payload
    );
    return ChargingPoint.parseFromObject(res.data);
  },
  [ChargingStationActions.CREATE_CHARGING_PRICE]: async (
    { commit },
    payload: {
      tenantId: string;
      name: string;
      price: number;
      current: string;
      plug?: Plug;
      userGroup?: string;
      blockingFeeBegin?: number;
      blockingFeeIncreaseAmount?: number;
      blockingFeeMaxPrice?: number;
    }
  ): Promise<ChargingPrice> => {
    const res = await ChargingPriceRepository.create(payload);
    return ChargingPrice.parseFromObject(res.data);
  },
  [ChargingStationActions.UPDATE_CHARGING_PRICE]: async (
    { commit },
    payload: {
      id: string;
      tenantId: string;
      name: string;
      price: number;
      current: string;
      plug?: Plug;
      userGroup?: string;
      blockingFeeBegin?: number;
      blockingFeeIncreaseAmount?: number;
      blockingFeeMaxPrice?: number;
    }
  ): Promise<ChargingPrice> => {
    const res = await ChargingPriceRepository.update(
      payload.id,
      payload.tenantId,
      payload.name,
      payload.price,
      payload.current,
      payload.plug,
      payload.userGroup,
      payload.blockingFeeBegin,
      payload.blockingFeeIncreaseAmount,
      payload.blockingFeeMaxPrice
    );
    return ChargingPrice.parseFromObject(res.data);
  },
  [ChargingStationActions.GET_ALL_CHARGING_PRICES]: async (): Promise<
    ChargingPrice[]
  > => {
    const res = await ChargingPriceRepository.getAll();
    const chargingStations: ChargingPrice[] = <ChargingPrice[]>(
      ChargingPrice.parseFromArray(res.data.records)
    );
    return chargingStations;
  },
};

const mutations: MutationTree<ChargingStationState> = {
  [ChargingStationMutations.SET_ALL]: (
    state,
    chargingStations: ChargingStation[]
  ) => {
    state.chargingStations = chargingStations;
  },
  [ChargingStationMutations.SET_TOTAL_CHARGE_LIST]: (state, total) => {
    state.total = total;
  },
  [ChargingStationMutations.SET_ALL_CHARGE_PROCESSES]: (
    state,
    chargeProcesses: ChargeProcess[]
  ) => {
    state.chargeProcesses = chargeProcesses;
  },
  [ChargingStationMutations.SET_CHARGING_STATION]: (
    state,
    chargingStation: ChargingStation
  ) => {
    state.chargingStation = chargingStation;
  },
  [ChargingStationMutations.CLEAR_STORE]: (state: ChargingStationState) => {
    // Merge rather than replace, so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initChargingStationState());
  },
};

const getters: GetterTree<ChargingStationState, any> = {
  [ChargingStationGetters.CHARGING_STATIONS]: (state: ChargingStationState) =>
    ChargingStation.parseFromObject(state.chargingStations),
  [ChargingStationGetters.CURRENT_CHARGING_STATION]: (
    state: ChargingStationState
  ) => ChargingStation.parseFromObject(state.chargingStation),
  [ChargingStationGetters.CURRENT_TOTAL_CHARGE_LIST]: (state: any) =>
    state.total,
};

const chargingStationStore: Module<ChargingStationState, any> = {
  namespaced: true,
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default chargingStationStore;
