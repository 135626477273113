import { CLIENT } from "@/api/misc/Client";
import { ChargingPointStatus } from "@/enum/ChargingPointStatus.enum";
import { Plug } from "@/enum/Plug.enum";

export default {
  /**
   * Create a charging point
   * @param payload
   */
  async create(payload: {
    evseId: string;
    status: ChargingPointStatus;
    plug: Plug;
    chargingStationId: string;
    isAvailableForCharging: boolean;
    requiresAuthentication: boolean;
    note?: string;
    tenantId: string;
  }) {
    return CLIENT.post("charging-point", payload);
  },

  /**
   * Delete one charging point by id
   */
  async deleteChargePoint(id: string) {
    return CLIENT.delete(`charging-point/${id}`);
  },

  /**
   * Update one charging point by id
   */
  async updateChargingPoint(
    id: string,
    plug: Plug,
    status: ChargingPointStatus,
    chargingStationId: string,
    evseId: string,
    isAvailableForCharging: boolean,
    requiresAuthentication: boolean
  ) {
    console.log(`ID ${id}`);

    return CLIENT.patch(`charging-point/${id}`, {
      plug,
      status,
      chargingStationId,
      evseId,
      isAvailableForCharging,
      requiresAuthentication,
    });
  },
};
