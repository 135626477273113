import store from "@/store";
import { AuthStoreGetters, AUTH_STORE_NAME } from "@/store/auth.store";
import ChargingStation from "@/models/ChargingStation";
import {
  CHARGING_STATION_STORE_NAME,
  ChargingStationGetters,
} from "@/store/chargingStation.store";

const AuthorizationPlugin = {
  install(Vue: any, options: any = {}) {
    const isLoggedIn = (): boolean => {
      const token =
        store.getters[`${AUTH_STORE_NAME}/${AuthStoreGetters.TOKEN}`];
      return token != undefined;
    };
    Vue.prototype.$isLoggedIn = isLoggedIn;
    Vue.isLoggedIn = isLoggedIn;
  },
};

export default AuthorizationPlugin;
