import ChargingPoint from "@/models/ChargingPoint";
import Gateway from "@/models/Gateway";
import { ChargingStationStatus } from "@/enum/ChargingStationStatus";
import { ChargingStationAccessibility } from "@/enum/ChargingStationAccessibility.enum";
import StationMessage from "@/models/StationMessage";
import Parseable from "@/models/Parseable";
import { Manufacturer } from "@/enum/Manufacturer.enum";

export default class ChargingStation extends Parseable {
  id!: number;
  name!: string;
  evseId!: string;
  simCard?: string;
  chargingPoints!: ChargingPoint[];
  status!: ChargingStationStatus;
  note!: string;
  street?: string;
  houseNr?: string;
  country?: string;
  postCode?: string;
  city?: string;
  isAc?: boolean;
  alwaysOpen?: boolean;
  startTime?: string;
  closeTime?: string;
  accessibility!: ChargingStationAccessibility;
  parkingLevel?: string;
  parkingNumber?: string;
  isOnlyForElectricVehicles?: boolean;
  isOnlyForMotorCycles?: boolean;
  isCustomerParking?: boolean;
  isOnlyAllowedDuringCharging?: boolean;
  isHandicappedParkingSpace?: boolean;
  isCarSharingSpace?: boolean;
  stationLat?: number | null;
  stationLong?: number | null;
  entryLat?: number | null;
  entryLong?: number | null;
  exitLat?: number | null;
  exitLong?: number | null;
  manufacturer!: Manufacturer;
  customManufacturer!: string;
  gateways?: Gateway[];
  stationMessages?: StationMessage[];

  static parseFromObject(object: any): ChargingStation {
    const chargingStation = new ChargingStation();
    Object.assign(chargingStation, object);

    chargingStation.chargingPoints = <ChargingPoint[]>(
      ChargingPoint.parseFromArray(chargingStation.chargingPoints!)
    );

    chargingStation.stationMessages = <StationMessage[]>(
      StationMessage.parseFromArray(chargingStation.stationMessages!)
    );

    return chargingStation;
  }

  parseToObject(): any {
    return { ...this };
  }
}
