import ApiError from "@/api/misc/ApiError";
import axios from "axios";

const ErrorHandlerPlugin = {
  install(Vue: any, options: any = {}) {
    const handleError = (
      error: any,
      customHandler: (() => any) | undefined = undefined
    ) => {
      if (error instanceof ApiError) {
        // Handling of exceptions that were thrown in response interceptors
        if (customHandler) {
          customHandler();
        } else {
          Vue.notifyError(
            Vue.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TITLE"),
            Vue.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TEXT")
          );
        }
      } else if (!axios.isAxiosError(error)) {
        // Axios errors are already handled in response interceptors
        Vue.notifyError(
          Vue.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TITLE"),
          Vue.$t("GENERAL.NOTIFICATIONS.GENERAL_ERROR.TEXT")
        );
      }
    };
    Vue.prototype.$handleError = handleError;
    Vue.handleError = handleError;
  },
};

export default ErrorHandlerPlugin;
