import Parseable from "@/models/Parseable";
import Role from "@/models/Role";
import User from "@/models/User";

/**
 * Charging Station company
 */
export default class Company extends Parseable {
  name!: string;

  roles!: Role[];

  users!: User[];

  static parseFromObject(object: any): Company {
    const company = new Company();
    Object.assign(company, object);

    company.roles = <Role[]>Role.parseFromArray(company.roles!);

    company.users = <User[]>User.parseFromArray(company.users!);

    return company;
  }

  parseToObject(): Partial<Company> {
    return { ...this };
  }
}
