import { CLIENT } from "@/api/misc/Client";

export default {
  /**
   * Start charging process of one charging station
   */
  async remoteStart(payload: {
    chargePointId: string;
    idTag: string;
    connectorId: number;
  }) {
    return CLIENT.post("ocpp/remoteStart/", payload);
  },

  /**
   * Stop charging process of one charging station
   */
  async remoteStop(payload: {
    chargePointId: string;
    transactionId: number;
    connectorId: number;
    idTag: string;
  }) {
    return CLIENT.post("ocpp/remoteStop/", payload);
  },

  /**
   * Reset one charging station
   */
  async reset(payload: { chargePointId: string; type: string }) {
    return CLIENT.post("ocpp/reset/", payload);
  },

  /**
   * Get Configuration of one charging station
   */
  async getConfig(payload: { chargePointId: string; key?: string[] }) {
    return CLIENT.get("ocpp/getConfiguration/", { params: payload });
  },

  /**
   * Update Configuration of one charging station
   */
  async updateConfiguration(payload: {
    chargePointId: string;
    key: string;
    value: string;
  }) {
    return CLIENT.post("ocpp/changeConfiguration/", payload);
  },

  /**
   * Change availability of one charging station
   */
  async changeAvailability(id: string) {
    console.log("Change Availability");
  },
};
