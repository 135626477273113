import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify/Vuetify.plugin";
import i18n from "@/i18n";
import CustomNotifications from "@/plugins/CustomNotifcations.plugin";
import "@/styles/main.scss";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import ErrorHandlerPlugin from "@/plugins/ErrorHandler.plugin";
import AuthorizationPlugin from "@/plugins/Authorization.plugin";

import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;

Vue.use(CustomNotifications);

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
/// Register custom plugins
Vue.use(Toast, {});
Vue.use(ErrorHandlerPlugin);
Vue.use(AuthorizationPlugin);
Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
