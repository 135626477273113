import { CLIENT } from "@/api/misc/Client";

export default {
  /**
   * Get list of all charge processes
   */
  async getAll() {
    return CLIENT.get("charge-process/");
  },

  /**
   * Get one charge process by id
   */
  async getChargeProcess(id: string) {
    return CLIENT.get(`charge-process${id}`);
  },

  /**
   * Update one charge process by id
   */
  async updateChargeProcess(id: string) {
    return CLIENT.patch(`charge-process/${id}`);
  },

  /**
   * Delete one charge process by id
   */
  async deleteChargeProcess(id: string) {
    return CLIENT.delete(`charge-process/${id}`);
  },

  /**
   * Download charge process as csv
   * @param value
   */
  async downloadChargeProcess(value?: string) {
    return CLIENT.get(`charge-process/download/csv/${value}`);
  },
};
