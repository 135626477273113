import Parseable from "@/models/Parseable";
import Company from "@/models/Company";
import Role from "@/models/Role";

/**
 * Represents the web user
 */
export default class User extends Parseable {
  public email!: string;
  public firstName!: string;
  public lastName!: string;
  public roleId!: string;
  public role!: Role;
  public roleName?: string;
  public phone!: string;
  public password!: string;
  public tenantId!: string;
  public company!: Company[];
  public id!: string;

  public static parseFromObject(object: any): User {
    const user = new User();
    Object.assign(user, object);
    user.company = <Company[]>Company.parseFromArray(user.company!);
    user.role = Role.parseFromObject(user.role!);
    return user;
  }

  parseToObject(): Partial<User> {
    return { ...this };
  }
}
