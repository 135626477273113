import Parseable from "@/models/Parseable";
import Company from "@/models/Company";
import User from "@/models/User";

/**
 * Role that represents accessibility for certain areas
 */
export default class Role extends Parseable {
  systemRole!: boolean;
  name!: string;
  company?: Company;
  users!: User[];
  id!: string;
  public superAdmin!: boolean;
  public tenantAdmin!: boolean;
  public global!: boolean;
  public draft!: boolean;
  tenantId!: string | null;

  static parseFromObject(object: any): Role {
    const role = new Role();
    Object.assign(role, object);

    role.company = Company.parseFromObject(role.company);
    role.users = <User[]>User.parseFromArray(role.users!);

    return role;
  }

  parseToObject(): Role {
    return { ...this };
  }
}
