import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import VuexPersistence from "vuex-persist";
import authStore, { AUTH_STORE_NAME } from "./auth.store";
import { RootState } from "@/interfaces/StoreStateInterfaces";
import chargingStationStore, {
  CHARGING_STATION_STORE_NAME,
} from "@/store/chargingStation.store";

Vue.use(Vuex);

export const enum StoreActions {
  CLEAR_ALL_STORES = "CLEAR_ALL_STORES",
}

// Persists auth and application module in local storage:
const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  key: process.env.VUE_APP_VUEX_KEY,
  modules: [AUTH_STORE_NAME, CHARGING_STATION_STORE_NAME],
});

const store: StoreOptions<RootState> = {
  plugins: [vuexLocal.plugin],
  actions: {
    [StoreActions.CLEAR_ALL_STORES]: ({ commit }) => {
      Object.keys(store.modules!).forEach((key: string) =>
        commit(`${key}/CLEAR_STORE`)
      );
    },
  },
  modules: {
    [AUTH_STORE_NAME]: {
      namespaced: true,
      ...authStore,
    },
    [CHARGING_STATION_STORE_NAME]: {
      namespaced: true,
      ...chargingStationStore,
    },
  },
};

export default new Vuex.Store<RootState>(store);
