import { AuthState, RootState } from "@/interfaces/StoreStateInterfaces";
import { ActionTree, GetterTree, Module, Mutation, MutationTree } from "vuex";
import User from "@/models/User";
import AuthRepository from "@/api/repositories/Auth.repository";
import Role from "@/models/Role";
import Tenant from "@/models/Tenant";
import TenantRepository from "@/api/repositories/TenantRepository";

export const AUTH_STORE_NAME = "auth";

export const enum AuthStoreActions {
  LOGIN = "LOGIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  CREATE_USER = "CREATE_USER",
  UPDATE_USER = "UPDATE_USER",
  GET_ALL = "GET_ALL",
  GET_ROLES = "GET_ROLES",
  GET_ROLE_BY_ID = "GET_ROLE_BY_ID",
  GET_ALL_TENANTS = "GET_ALL_TENANTS",
  GET_USER_BY_ID = "GET_USER_BY_ID",
  SET_PASSWORD = "SET_PASSWORD",
  GET_USERS_BY_TENANT_ID = "GET_USERS_BY_TENANT_ID",
}

export const enum AuthStoreMutations {
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  SET_TOKEN = "SET_TOKEN",
  SET_USER = "SET_USER",
  SET_ACCOUNT = "SET_ACCOUNT",
  CLEAR_STORE = "CLEAR_STORE",
  SET_ALL_USER = "SET_ALL_USER",
}

export const enum AuthStoreGetters {
  REFRESH_TOKEN = "REFRESH_TOKEN",
  TOKEN = "TOKEN",
  CURRENT_USER = "CURRENT_USER",
  CURRENT_ACCOUNT = "CURRENT_ACCOUNT",
}

function initialAuthState(): AuthState {
  return {
    refreshToken: null,
    token: null,
    currentUser: null,
    users: [],
  };
}

const store: AuthState = initialAuthState();

const actions: ActionTree<AuthState, RootState> = {
  [AuthStoreActions.LOGIN]: async (
    { commit },
    payload: { username: string; password: string }
  ): Promise<User> => {
    const res = await AuthRepository.login(payload);

    // Save tokens to store
    commit(AuthStoreMutations.SET_TOKEN, res.data.access_token);
    commit(AuthStoreMutations.SET_REFRESH_TOKEN, res.data.refresh_token);

    const user = User.parseFromObject(res.data.user);

    // Save user to store
    commit(AuthStoreMutations.SET_USER, user);

    return user;
  },
  [AuthStoreActions.UPDATE_USER]: async (
    { commit },
    payload: {
      id: string;
      tenantId: string;
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      phone: string;
      roleId: string;
      updateCurrentUser: boolean;
    }
  ): Promise<User> => {
    const res = await AuthRepository.updateUser(
      payload.id,
      payload.tenantId,
      payload.email,
      payload.password,
      payload.firstName,
      payload.lastName,
      payload.roleId,
      payload.phone
    );
    const user = User.parseFromObject(res.data);
    if (payload.updateCurrentUser) {
      // Save user to store
      commit(AuthStoreMutations.SET_USER, user);
    }
    return user;
  },
  [AuthStoreActions.CREATE_USER]: async (
    { commit },
    payload: {
      tenantId: string | null;
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      phone: string;
      roleId: string;
    }
  ): Promise<User> => {
    const res = await AuthRepository.createUser(payload);
    const user = User.parseFromObject(res.data);
    return user;
  },
  [AuthStoreActions.GET_ALL]: async ({ commit }): Promise<User[]> => {
    const res = await AuthRepository.getAll();
    const users: User[] = <User[]>User.parseFromArray(res.data.records);
    commit(AuthStoreMutations.SET_ALL_USER, users);
    return users;
  },
  [AuthStoreActions.GET_ROLES]: async ({ commit }): Promise<Role[]> => {
    const res = await AuthRepository.getAllRoles();
    const roles: Role[] = <Role[]>Role.parseFromArray(res.data.records);
    return roles;
  },
  [AuthStoreActions.GET_USER_BY_ID]: async (
    { commit },
    id: string
  ): Promise<User> => {
    const res = await AuthRepository.getUserById(id);
    const user: User = User.parseFromObject(res.data);
    return user;
  },
  [AuthStoreActions.GET_ROLE_BY_ID]: async (
    { commit },
    payload: { id: string }
  ): Promise<Role> => {
    const res = await AuthRepository.getRoleById(payload.id);
    return Role.parseFromObject(res.data);
  },
  [AuthStoreActions.GET_ALL_TENANTS]: async ({ commit }): Promise<Tenant[]> => {
    const res = await TenantRepository.getAll();
    const tenants: Tenant[] = <Tenant[]>Tenant.parseFromArray(res.data.records);
    return tenants;
  },
  [AuthStoreActions.GET_USERS_BY_TENANT_ID]: async (
    { commit },
    id: string
  ): Promise<User[]> => {
    const res = await TenantRepository.getByTenantId(id);
    const users: User[] = <User[]>User.parseFromArray(res.data.records);
    return users;
  },
  [AuthStoreActions.SET_PASSWORD]: async (
    { commit },
    payload: { hash: string; password: string }
  ): Promise<User> => {
    const res = await AuthRepository.setPassword(payload);
    return User.parseFromObject(res.data.user);
  },
};

const mutations: MutationTree<AuthState> = {
  [AuthStoreMutations.SET_TOKEN]: (
    state: AuthState,
    value: string | undefined
  ) => {
    state.token = value;
  },
  [AuthStoreMutations.SET_REFRESH_TOKEN]: (
    state: AuthState,
    value: string | undefined
  ) => {
    state.refreshToken = value;
  },
  [AuthStoreMutations.SET_USER]: (state: AuthState, value: User) => {
    state.currentUser = value;
  },

  [AuthStoreMutations.CLEAR_STORE]: (state: AuthState) => {
    // Merge rather than replace, so we don't lose observers
    // https://stackoverflow.com/questions/42295340/how-to-clear-state-in-vuex-store
    Object.assign(state, initialAuthState());
  },
  [AuthStoreMutations.SET_ALL_USER]: (state, users: User[]) => {
    state.users = users;
  },
};

const getters: GetterTree<AuthState, RootState> = {
  [AuthStoreGetters.TOKEN]: (state: AuthState) => state.token,
  [AuthStoreGetters.REFRESH_TOKEN]: (state: AuthState) => state.refreshToken,
  [AuthStoreGetters.CURRENT_USER]: (state: AuthState) => state.currentUser,
};

const authStore: Module<AuthState, RootState> = {
  state: store,
  actions: actions,
  mutations: mutations,
  getters: getters,
};

export default authStore;
