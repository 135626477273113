import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import {
  ROUTE_CHARGING_STATION_DASHBOARD,
  ROUTE_CHARGING_PROCESS_OVERVIEW,
  ROUTE_LOGIN,
  ROUTE_REGISTER,
  ROUTE_PASSWORD,
  ROUTE_CHARGING_STATION,
  ROUTE_SETTINGS,
  ROUTE_SET_PASSWORD,
} from "@/router/routes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    name: ROUTE_LOGIN,
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/authentication/Login.view.vue"
      ),
  },
  {
    path: "/settings",
    name: ROUTE_SETTINGS,
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/Settings.view.vue"
      ),
  },
  {
    path: "/charging-point-overview",
    name: ROUTE_CHARGING_STATION_DASHBOARD,
    component: () =>
      import(
        /* webpackChunkName: "charging-point-overview-dashboard" */ "../views/dashboard/ChargingStationOverview.view.vue"
      ),
  },
  {
    path: "/charging-process-overview",
    name: ROUTE_CHARGING_PROCESS_OVERVIEW,
    component: () =>
      import(
        /* webpackChunkName: "charging-station-dashboard" */ "../views/charge-process/ChargeProcessOverview.view.vue"
      ),
  },
  {
    path: "/charging-point-overview/:id",
    name: ROUTE_CHARGING_STATION,
    component: () =>
      import(
        /* webpackChunkName: "charging-point-overview"*/ "../views/charging-station/ChargingPointOverview.view.vue"
      ),
  },
  {
    path: "/register",
    name: ROUTE_REGISTER,
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/authentication/Register.view.vue"
      ),
  },
  {
    path: "/password",
    name: ROUTE_PASSWORD,
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/authentication/Password.view.vue"
      ),
  },
  {
    path: "/set-password/:hash",
    name: ROUTE_SET_PASSWORD,
    component: () => import("../views/authentication/SetPassword.view.vue"),
  },
  {
    path: "*",
    redirect: { name: ROUTE_LOGIN },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
